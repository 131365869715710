<template>
  <div class="right" style="padding: 20px;">
    <div v-show="tab == 0">
      <div style="margin-bottom: 30px">基本信息</div>
      <el-form label-width="80px" >
        <el-form-item label="题目" >
          <div style="width: 300px">
            <el-input placeholder="请输入题目" class="addInput"></el-input>
          </div>
        </el-form-item>

        <el-form-item label="作者" >
          <div style="width: 300px">
            <el-input
              type="text"
              placeholder="请输入作者"
              class="addInput"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="检索网站" >
          <el-button
            class="btns"
            v-for="(list, index) in leftPart"
            :key="index"
            @click="leftChange(index)"
            :class="{ cur: tabs == index }"
            ><img class="wanfang" src="@/assets/image/enter/wanfang.png"/></el-button
          >
        </el-form-item>

        <div style="margin-bottom: 30px">上传附件</div>
        <el-form-item label="附件" prop="realname">
          <div class="file">
            <i class="el-icon-paperclip"></i><a>点击上传附件</a>
          </div>
        </el-form-item>
        <div class="shixiang">
          <div style="margin-bottom: 15px">注意事项</div>
          <p>1.为确保检测的准确性，请您提交论文的全备份</p>
          <p>
            2.支持文档格式：WORD文件（.doc
            .docx）、文本文件（.txt）PDF文件（.pdf）。
          </p>
          <p>3.上传文档最大限制为20MB</p>
          <p>4.每位用户拥有3次免费查重次数</p>
        </div>

        <div style="margin: 30px 0px 42px 60px">
          <el-button
            type="danger"
            @click="ChaTap()"
            style="width: 100px; background: red; border: red"
            >查询</el-button
          >
        </div>
      </el-form>
      <el-dialog
        title="智能查重"
        :visible.sync="dialog"
        width="450px"
        :close-on-click-modal="false"
      >
        <div>
          <el-form ref="form">
            <div>您的3次免费查重次数已用完，请缴费购买</div>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialog = false">取 消</el-button>
          <el-button type="danger" @click="ChaTap()">确 定</el-button>
        </div>
      </el-dialog>
    </div>

    <div v-show="tab == 1">查询结果

      <div class="xs">
			
			</div>
			
			<div class="file" @click="downloadAttach">
				<i class="el-icon-paperclip"></i><a>点击下载附件</a>
			</div>
   
    </div>
    </div>
  </div>
</template>

<script>
import { Balanced, BalancedList } from '../../../API/Fuwu'

export default {
  components: {

  },
  data () {
    return {
      activeName: 'first', //默认选项卡初始化1
      tab: 0,
      tabs: 0,// 0基本信息 1 查重结果
      dialog: false,
      loading: false,

      leftPart: [
        { name: "万方" },
        // { name: "维普" },
      ],
      departments: [], // 职务
      departmentList: [], // 职级 

    };
  },
  mounted () {
  },

  methods: {
    ChaTap () {
      this.tab = 1
      //  this.$router.push('/Pending/subCheck/?id=5')
    },
    handleClick (tab, event) {
    },
    // 更改表头样式
    headeRowClass ({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #F2F2F2;color: #666666;font-weight: 700;'
      }
    },
    // 分页
    current_change: function (currentPage) {
      this.currentPage = currentPage;

    }, handleSizeChange (val) {
      console.log(val);
      this.pagesize = val;
    },

    //弹窗
    receive: function (row) {
      //显示弹框
      this.dialog = true;

    },

  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.headerImg {
  object-fit: cover;
  object-position: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 50px 150px;
}
.headerInput {
  position: absolute;
  top: 220px;
  left: 43%;
  right: 0;
  width: 8%;
  height: 35%;
  border-radius: 50%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}
.photo {
  width: 150px !important;
  height: 150px !important;
}
.cur {
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#ff0000), to(#ff0000));
}

.btns {
  /* width: 100px; */
  padding: 6px;
}
b {
  color: #000000;
}

.file {
  width: 50%;
  height: 40px;
  background: #f7f7f7;
  line-height: 40px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding-left: 20px;
  box-sizing: border-box;
  cursor: pointer;
}
.file span {
  font-size: 14px;
}
.shixiang p {
  width: auto;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.bt {
  width: 50px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
 
}

.xs .author {
  margin-right: 10px;
}
.wanfang{
  width: 100px;
}
</style>