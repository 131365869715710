<template>
  <div class="right">
    <div style="margin-top: 27px">
      <div class="title">
        <div class="container_table">
          <el-table
            :header-cell-style="headeRowClass"
            :data="tableData"
            stripe
            style="width: 100%"
            :default-sort="{ prop: 'date', order: 'descending' }"
          >
            <el-table-column
              prop="numbers"
              label="题目"
              width="300"
              align="center"
              sortable
            >
            </el-table-column>
            <el-table-column
              prop="txtime"
              label="作者"
              width="180"
              align="center"
              sortable
            >
            </el-table-column>
            <el-table-column prop="money" label="查重时间" align="center" sortable>
            </el-table-column>
          </el-table>
          <el-pagination
            class="fy"
            small
            @size-change="handleSizeChange"
            :page-size="pagesize"
            :page-sizes="[10, 20, 30]"
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="current_change"
            :total="total"
            background
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Balanced, BalancedList } from '../../../API/Fuwu'

export default {
  components: {
  },
  data () {
    return {
      activeName: 'first', //默认选项卡初始化1
      yue: '', // 余额
      years: '',
      nian: '',// 年
      tab: 0,//0 列表   1提现页面
      total: 0,//默认数据总数
      pagesize: 10,//每页的数据条数
      currentPage: 1,//默认开始页面
      tableData: [], // 提现记录列表
    };
  },
  mounted () {
    this.balanced(); /*初始化数据都叫这个名字*/
    this.BalancedList();
  },

  methods: {
    handleClick (tab, event) {
    },
    // 更改表头样式
    headeRowClass ({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #F2F2F2;color: #666666;font-weight: 700;'
      }
    },
    // 分页
    current_change: function (currentPage) {
      this.currentPage = currentPage;
      this.BalancedList()
    }, handleSizeChange (val) {
      console.log(val);
      this.pagesize = val;
      this.BalancedList();
    },
    // 提现
    TixianTap () {
      this.$router.push('/Services/widthdraw/?id=0')
    },
    // 查询余额接口
    balanced () {
      let id = localStorage.getItem('FuWuId')

      Balanced(id).then(res => {
        this.yue = res.data.data
      })
    },
    // 提现记录列表
    BalancedList () {
      let id = localStorage.getItem('FuWuId')
      BalancedList(id, this.currentPage, this.pagesize).then(res => {
        this.tableData = res.data.data
        console.log(res)
        this.total = res.data.total
      })
    },

  },
};
</script>

<style  scoped>
.yue {
  width: 100%;
  height: 110px;
}
.yue-list {
  width: 100%;

  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.fy {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 27px;
}
</style>