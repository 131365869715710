import request from '@/utils/request'
import qs from 'qs'
// 服务余额
export function Balanced (id) {
  let data = {
    id
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/chayue',
    method: 'post',
    data
  })
}
// 提现列表
export function BalancedList (id,page,limit) {
  let data = {
    id,
    page,
    limit

  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/tixianjilu',
    method: 'post',
    data
  })
}
// 流水记录
export function BalancedLiuList (fwsId,year) {
  let data = {
    fwsId,year
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/liushui',
    method: 'post',
    data
  })
}
// 提现
export function BalancedTXList (id,jine,zhanghu,name,type) {
  let data = {
    id,jine,zhanghu,name,type
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/tixianjilu',
    method: 'post',
    data
  })
}