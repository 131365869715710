<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="智能查重" name="first">
          <Rechecking ></Rechecking>
	</el-tab-pane>
      <el-tab-pane label="查重记录" name="second"><Censusrecords></Censusrecords></el-tab-pane>
    </el-tabs>
  </div>
</template>
       

<script>

import Rechecking from './rechecking'
import Censusrecords from './censusrecords'
export default {
  components: {
    Rechecking,
    Censusrecords
  },
  data () {
    return {
      activeName: 'first',
    }

  },
  mounted () {

  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event);
    }
  }
}

</script>

<style>
</style>